import React from 'react';
import { GridButton } from '../components/Common'


export function ContinueButton(props) {
    return (
        <GridButton {...props}>
            Continue
        </GridButton>
    )
}

export function SubmitButton(props) {
    return (
        <GridButton {...props}>
            Submit
        </GridButton>
    )
}
