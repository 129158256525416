import React, {Fragment} from 'react';
import { Button, Grid, Backdrop, CircularProgress, Typography, Container,
    TextField, Paper, FormControlLabel, Checkbox
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles'

const baseTheme = createMuiTheme({
    palette: {
    },
    typography: {
    }
});

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    backdrop: {
        zIndex: 99,
        color: '#FFFFFF',
    },
}))

export function Base(props) {
    return (
        <ThemeProvider theme={baseTheme}>
            <Container disableGutters={true} component="main" maxWidth="xs">
                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                    {props.children}
                </Grid>
            </Container>
        </ThemeProvider>
    )
}

export function Form(props) {
    const classes = useStyles();
    return (
        <div className={classes.paper}>
            { props.isLoading || false ?
                (
                    <LoadingBackdrop open={props.isLoading}/>
                ) : (
                    <div>
                        {props.children}
                    </div>
                )
            }
        </div>
    );
};

export function FormSection(props) {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <Paper className={classes.paper}>
              {props.children}
            </Paper>
        </Grid>
    )
  };


// Base Grid Pieces
export function GridContainer(props) {
    return (
        <Grid container spacing={props.spacing || 2} justifyContent={props.justify || 'flex-end'} {...props}>
            {props.children}
        </Grid>
    )
}

export function GridItem(props) {
    return (
        <Grid item xs={props.xs || 12} {...props} >
            { props.isLoading || false ?
                (
                    <GridLoading/>
                ) : (
                    <div>
                        {props.children}
                    </div>
                )
            }
        </Grid>
    )
}

export function GridButton(props) {
    return (
        <GridItem {...props}>
            <Button variant='contained' color= "primary" >
                {props.children}
            </Button>
        </GridItem>
    )
}

export function GridTypography(props) {
    return (
        <GridItem xs={props.xs || 12} md={9}>
            <Typography justify="center" display='inline' color={props.color || "primary"}>
                {props.children}
            </Typography>
        </GridItem>
    )
}

export function GridCheckbox(props) {
    return (
        <GridItem xs={3}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.checked}
                        onChange={props.onChange}
                        color='primary'
                    />
                }
                label={props.label || "Verified"}
            />
        </GridItem>
    )
}

export function GridLoading(props) {
    return (
        <Grid container justifyContent='flex-end'>
            <CircularProgress />
        </Grid>
    )
}

export function GridStatus(props) {
    return (
        <Fragment>
            { props.error &&
                <Grid item xs={12} md='auto'>
                    <Typography color='error'>
                        <ClearIcon color='error' />
                        {props.error}
                    </Typography>
                </Grid>
            }
            { props.success &&
                <Grid item xs={12} md='auto'>
                    <Typography color='primary'>
                        <CheckIcon color='primary' />
                        {props.success}
                    </Typography>
                </Grid>
            }
        </Fragment>
    )
}

export function GridTextField(props) {
    const [selected, setSelected] = React.useState(false)
    const [value, setValue] = React.useState(props.value || props.defaultValue || '')
    const validationFunction = props.validationFunction || ((value) => {return true})
    const onValidInput = props.onValidInput || ((value) => {})
    const onChange = (e) => {
        setValue(e.target.value)
        setSelected(true)
        if (typeof props.onChange === "function"){
            props.onChange(e)
        }
        if (validationFunction(value)) {
            onValidInput(e)
        }
    }
    const onBlur = () => {
        setSelected(true)
        if (typeof props.onBlur === "function") {
            props.onBlur()
        }
    }
    const onKeyPress = (e) => {
        if (typeof props.onEnter === "function" && e.key === 'Enter') {
            props.onEnter(e)
        }
        if (typeof props.onKeyPress === "function") {
            props.onKeyPress(e)
        }
    }

    return (
        <Grid item xs={props.xs || 6} {...props}>
            <TextField
                {...props}
                fullWidth
                value={value}
                margin={props.margin || "normal"}
                InputLabelProps={props.InputLabelProps || {
                    shrink: true,
                }}
                error={selected && !validationFunction(value)}
                onChange={onChange}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
            />
        </Grid>
    )
}


// Page wide effects
export function LoadingBackdrop(props) {
    const classes = useStyles();
    return (
        <Backdrop {...props} className={classes.backdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}



