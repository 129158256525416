import React from 'react'
import { Base, FormSection, GridTypography, GridStatus, GridButton } from '../components/Common'
import { postAPI } from '../utils'

// eslint-disable-next-line
export default function () {
    const [isLoading, setIsLoading] = React.useState(false)
    const [inputError, setInputError] = React.useState('')
    const [inputSuccess, setInputSuccess] = React.useState('')

    const handleProcessing = () => {
        setIsLoading(true)
        setInputError('')
        setInputSuccess('')
    }

    const onTestClick = React.useCallback((event) => {
        if (event) {event.preventDefault()}
        if (isLoading) {return}
        handleProcessing()
        console.log("intestclick")
        setIsLoading(true)
        var body = {
            key: "value"
        }
        postAPI('/somepath', body)
        .then((res) => {
            setInputSuccess("successful response")
            setIsLoading(false)
            console.log(res)
        })
        .catch((err) => {
            console.error(err)
            setInputError("Something Went Wrong")
            setIsLoading(false)
        })
    }, [isLoading, setIsLoading])

    return (
        <Base>
            <FormSection>
                <GridTypography>
                    Welcome to BBB
                </GridTypography>
                <GridStatus 
                    success={inputSuccess}
                    error={inputError}
                />
                <GridButton 
                    isLoading={isLoading}
                    onClick={onTestClick}
                >
                    API Test
                </GridButton>
            </FormSection>
        </Base>
    )
}
